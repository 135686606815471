.slick-slide div {
    outline: none;
}

.backdropImage {
    height: auto;
    width: 80%;
    max-width: 800px;
}

@media only screen and (min-width: 768px) {
    .backdropImage {
    }
}
