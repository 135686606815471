.lineActive {
    stroke: rgb(117, 117, 117);
    /* -webkit-filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.8));
    filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.8)); */
    transition-property: stroke;
    transition-duration: 2s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}


.links line {
    stroke-opacity: 1;
}

.lineDisabled {
    stroke: rgb(221, 221, 221);
    transition-property: stroke;
    transition-duration: 2s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}


.canvasHolder {
    width: 100%;
    height: 100%;
}


.nodes circle {
    stroke: #fff;
    stroke-width: 1px;
}

.nodes circle:hover {
    cursor: pointer;
}

.active {
    /* rip shadow was nice but also ate up cpu :(
    /* -webkit-filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.8));
    filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.8));

    transition-property: -webkit-filter, filter;
    transition-duration: 2s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); */
}

.ignored {
    /* stroke: rgb(80, 80, 80);
    stroke-width: 10px;
    -webkit-filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, .1)) grayscale(100%);
    filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, .1)) grayscale(100%);
    
    transition-property: -webkit-filter, filter;
    transition-duration: 2s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); */
}

@media only screen and (min-width: 600px) {
    /* performance... */
  .ignored {
          stroke: rgb(80, 80, 80);
    stroke-width: 10px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    
    transition-property: -webkit-filter, filter;
    transition-duration: 2s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  }
}


.ignored:hover {
    /* -webkit-filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.8));
    filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.8));
    transition-property: -webkit-filter, filter;
    transition-duration: 2s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); */
}
