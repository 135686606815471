.App {
  text-align: center;
  min-height: 100%;
}

a, a:visited, a:hover, a:active {
  color: inherit;
}

.notFoundImage {
  width: 50%;
  max-width: 400px;
  -webkit-filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, .1)) grayscale(100%);
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, .1)) grayscale(100%);
}